<template>
<div class="sinoepc-weifang-index">
  <div class="page flex-col">
      <div class="box1 flex-col">
        <div class="main3 flex-col justify-between">
            <div v-for="(coupon,index) in couponList" :key="index">
              <van-image
                     :class="coupon.css"
                     referrerpolicy="no-referrer"
                     :src="coupon.image"
                     @click="onView('/sinoepc-weifang/' + coupon.name)"
               />
            </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
import { Image as VanImage } from 'vant';
export default {
  name: 'SinoepcWeifangIndex',
  components: {
     [VanImage.name]: VanImage,
  },
  data() {
    return {
      //优惠券列表
      couponList: [
           {name: 'qingzhou', image: require('../../../assets/sinoepc/qingzhou.png'), css: "img1" },
           {name: 'linqu', image: require('../../../assets/sinoepc/weifangnewcar.png'), css: "img2" },
      ]
    }
  },
  methods: {
    onView(url) {
      return this.$router.push({ path: url }).catch(()=>{});
    }
  }
};
</script>
<style scoped>@import '../../../assets/voucher/common.css';</style>
<style scoped>
.page {
  position: relative;
  width: 100vw;
  height: 194.67vw;
  overflow: hidden;
}

.box1 {
   height: 194.67vw;
   background: url(../../../assets/sinoepc/weifang-index.png) bottom center no-repeat #efeff4;
   background-size: 100% 100%;
   width: 100%;
   height: 100%;
}

.main3 {
  width: 82.67vw;
  height: 64.14vw;
  margin: 113.33vw 0 0 8.4vw;
}

.img1 {
  width: 82.4vw;
  height: 28.14vw;
  margin-left: 0.27vw;
}

.img2 {
  width: 82.4vw;
  height: 28.14vw;
  margin-top: 3.87vw;
}
</style>

